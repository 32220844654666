import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';
import uploadLoader from '../assets/spinning-circles.svg';

const UploadingToKb = () => {
  return (
        <div className='file-deletion-container'>
            <Fade>
                <label>Uploading User Input to Knowledge Base<img src={uploadLoader} alt ='loader'></img></label>
            </Fade>
        </div>
  );
};

export default UploadingToKb;
