import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import demoVideo from '../assets/demo-final.mp4';
import rightarrow from '../assets/right-arrow.svg';
import '../styles/Hero.css';
import Launches from './Launches';

const Hero = ({ time = '20s' }) => {
  const { currentUser } = useAuth();
  const style = {
    '--time': time,
  };

  useEffect(() => {
    const videoElement = document.querySelector('.hero-video video');
    if (videoElement) {
      videoElement.load();
    }
  }, []);

  return (
    <div className="overarching-hero-container">
      <Helmet>
        <title>ragobble - Empowering Academic Research</title>
        <meta
          name="description"
          content="Transform your academic resources into dynamic, AI-powered conversations. Upload lectures, slides, notes, recordings, PDFs, YouTube URLs, article URLs, and more to get instant, cited answers. Try it for FREE!"
        />
        <meta
          name="keywords"
          content="AI, academic research, chat, education, AI-powered, lectures, slides, notes, recordings, PDFs, YouTube URLs, article URLs, ragobble"
        />
        <meta name="author" content="ragobble" />
        <meta property="og:title" content="ragobble - Empowering Academic Research" />
        <meta
          property="og:description"
          content="Transform your academic resources into dynamic, AI-powered conversations. Upload lectures, slides, notes, recordings, PDFs, YouTube URLs, article URLs, and more to get instant, cited answers. Try it for FREE!"
        />
        <meta property="og:image" content="/path/to/social-image.jpg" />
        <meta property="og:url" content="https://www.ragobble.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ragobble - Empowering Academic Research" />
        <meta
          name="twitter:description"
          content="Transform your academic resources into dynamic, AI-powered conversations. Upload lectures, slides, notes, recordings, PDFs, YouTube URLs, article URLs, and more to get instant, cited answers. Try it for FREE!"
        />
        <meta name="twitter:image" content="/path/to/social-image.jpg" />
      </Helmet>
      <Launches />
      <header className="hero-container">
        <Fade cascade delay={100}>
          <div className="hero-text">
            <h1>
              Chat with Your Class
              <br />
              Instant, Cited Answers
            </h1>
            <br />
            <p>
              Upload any notes, videos, PDFs or files from your class and chat with them with advanced retrieval.
            </p>
            <br />
            <Link to={currentUser ? '/workbench' : '/signup'}>
              <button className="button-view-tx">
                {currentUser ? 'Go to Workbench' : 'Get Started for FREE'}
                <img src={rightarrow} className="hero-arrow" alt="Arrow pointing to the right" />
              </button>
            </Link>
          </div>
        </Fade>
        <Fade delay={250}>
          <div className="hero-video">
            <video src={demoVideo} autoPlay loop muted playsInline></video>
          </div>
        </Fade>
      </header>
    </div>
  );
};

export default Hero;
