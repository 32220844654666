import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from "react-router-dom";
import { Fade } from 'react-reveal';
import { useAuth } from '../auth/AuthContext';
import Logo from '../assets/ragobble.svg';
import avatar from '../assets/avatar-final.svg';
import logoutpic from '../assets/logout.svg';
import ResourcesDropdown from './ResourcesDropdown';
import { Squeeze as Hamburger } from 'hamburger-react';
import halfsquare from '../assets/arightfooter.svg';
import '../styles/Navigation.css';

const Navigation = () => {
    const location = useLocation();
    const { currentUser, logout } = useAuth();
    const isSignupRoute = location.pathname === '/signup' || location.pathname === '/signin';
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [menuOpen]);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <Helmet>
                <meta name="description" content="Navigate through Ragobble's AI-Driven search platform. Access the workbench, pricing, resources, and account settings." />
                <meta name="keywords" content="AI, search, navigation, workbench, pricing, resources, Ragobble" />
                <meta name="author" content="Ragobble" />
                <meta property="og:description" content="Navigate through Ragobble's AI-Driven search platform. Access the workbench, pricing, resources, and account settings." />
                <meta property="og:image" content="/path/to/social-image.jpg" />
                <meta property="og:url" content="https://www.ragobble.com/navigation" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content="Navigate through Ragobble's AI-Driven search platform. Access the workbench, pricing, resources, and account settings." />
                <meta name="twitter:image" content="/path/to/social-image.jpg" />
            </Helmet>
            {isSignupRoute ? (
                <div className="signup-navigation">
                    <div className="signup-nav-inner">
                        <section className="navigation-logo">
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <section className="navigation-logo-text">
                                    <img src={Logo} alt="Ragobble logo" />
                                    <section className="tdp-title-container">
                                        <h6 className="tdp-first">ragobble</h6>
                                    </section>
                                </section>
                            </Link>
                        </section>
                    </div>
                </div>
            ) : (
                <Fade>
                    <nav className="navigation-outer">
                        <section className="navigation-banner">
                            <section className="navigation-logo">
                                <Link to="/" style={{ textDecoration: 'none' }}>
                                    <section className="navigation-logo-text">
                                        <img src={Logo} alt="Ragobble logo" />
                                        <section className="tdp-title-container">
                                            <h6 className="tdp-first">ragobble</h6>
                                        </section>
                                    </section>
                                </Link>
                            </section>
                            <section className="navigation-list">
                                <ul>
                                    <Link to="/workbench" style={{ textDecoration: 'none' }}><li>Workbench</li></Link>
                                    {location.pathname === '/' ? (
                                        <a href="/pricing" style={{ textDecoration: 'none' }}><li>Pricing</li></a>
                                    ) : (
                                        <Link to="/pricing" style={{ textDecoration: 'none' }}><li>Pricing</li></Link>
                                    )}
                                    <ResourcesDropdown />
                                </ul>
                            </section>
                            {!currentUser ? (
                                <>
                                    <section className="nav-account-btns">
                                        <Link to="/signin"><button className="nav-signin-btn">Sign In</button></Link>
                                        <Link to="/signup"><button className="nav-signup-btn">Get Started</button></Link>
                                    </section>
                                    <div className="burger-nav">
                                        <Hamburger
                                            color="#000000"
                                            size="25"
                                            style={{ margin: '0px', padding: '0' }}
                                            onToggle={toggleMenu}
                                        />
                                        {menuOpen && (
                                            <div className="menu">
                                                <ul>
                                                    <label>Account</label>
                                                    <Link to="/signin" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Sign In</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="/signup" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Sign Up</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="/workbench" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Workbench</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <label>Resources</label>
                                                    <Link to="/about" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">About Us</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="/pricing" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Pricing</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="/privacy" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Privacy</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="/ToS" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">ToS</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="mailto:admin@ragobble.com" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Support</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="burger-nav" style={{ marginRight: '-30%' }}>
                                        <Hamburger
                                            color="#ffffff"
                                            size="25"
                                            style={{ margin: '0px', padding: '0' }}
                                            onToggle={toggleMenu}
                                        />
                                        {menuOpen && (
                                            <div className="menu">
                                                <ul>
                                                    <Link to="/workbench" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Workbench</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <label>Resources</label>
                                                    <Link to="/about" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">About Us</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="/privacy" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Privacy</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="/ToS" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">ToS</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <Link to="mailto:support@thedarkpool.io" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <li className="ham-link-mobile">Support</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                    <div className="account-loggedin">
                                                        <label>Account</label>
                                                        <label>{currentUser.email}</label>
                                                    </div>
                                                    <Link to="/" style={{ textDecoration: 'none', width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={logout}>
                                                        <li className="ham-link-mobile">Log Out</li>
                                                        <img alt="halfsquare" src={halfsquare} />
                                                    </Link>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <section className={`logins ${dropdownVisible ? 'active' : ''}`}>
                                        <img
                                            className="account-avatar"
                                            src={avatar}
                                            alt="User avatar"
                                            onClick={toggleDropdown}
                                        />
                                        <section className={`logins-dropdown ${dropdownVisible ? 'visible' : ''}`}>
                                            <p className="currentuser">{currentUser.email}</p>
                                            {currentUser.subscription.isAdvancedUser ? (
                                                <p className="currentuser">Advanced Tier</p>
                                            ) : currentUser.subscription.isStandardUser ? (
                                                <p className="currentuser">Standard Tier</p>
                                            ) : (
                                                <p className="currentuser">Unsubscribed</p>
                                            )}
                                            <Link to="/signup" style={{ textDecoration: 'none', width: '100%', margin: '0', padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <button className="dropdown-element" onClick={logout}>
                                                    <label>Log Out</label>
                                                    <img src={logoutpic} alt="Logout icon" />
                                                </button>
                                            </Link>
                                        </section>
                                    </section>
                                </>
                            )}
                        </section>
                    </nav>
                </Fade>
            )}
        </>
    );
};

export default Navigation;
