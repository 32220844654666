import React, { useState } from 'react';
import '../styles/Faq.css';
import arrowImage from '../assets/plus-faq.svg';

const FaqComponent = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const faqData = [
    {
      question: 'What is ragobble?',
      answer: [
        'ragobble is a platform that empowers students to interact with all their class materials through conversation. Upload documents, videos, URLs, and files from your course and chat with the collective knowledge of your class.',
        'Our advanced AI processes your content to provide quick, contextual answers, making your study sessions more effective.'
      ],
    },
    {
      question: 'How does it work?',
      answer: [
        'Simply navigate to the Workbench section and add your class materials such as lecture notes, articles, videos, and more. Once uploaded, these materials are processed and indexed for easy retrieval.',
        'Start a chat session, ask questions about your content, and receive tailored responses that help clarify your coursework.'
      ],
    },
    {
      question: 'What types of content can I upload?',
      answer: [
        'You can upload a variety of content including documents (PDFs, Word files, etc.), YouTube videos, article URLs, and other file formats. This ensures all your study materials are available in one place.'
      ],
    },
    {
      question: 'How is my data secured?',
      answer: [
        'We prioritize your privacy and security. All uploads are encrypted and stored securely, ensuring that your class materials remain private and accessible only to you and your designated peers.'
      ],
    },
    {
      question: 'Is ragobble accurate?',
      answer: [
        'Yes, ragobble leverages state-of-the-art AI retrieval algorithms that cross-reference and verify the information from your uploaded materials to provide accurate, context-driven answers.',
        'While designed for precision, we always recommend double-checking critical information with your original course materials and trusted sources for complete assurance.'
      ],
    },
    {
      question: 'I need more help. How can I get support?',
      answer: [
        'If you need further assistance, please contact our support team at <br></br><a href="mailto:support@ragobble.com.com">support@ragobble.com</a>'
      ],
    },
  ];

  return (
    <section className='faq-container'>
      <h1>Frequently Asked Questions</h1>
      <section className='accordion'>
        {faqData.map((item, index) => (
          <div className='accordion-item' key={index}>
            <button
              onClick={() => toggleAccordion(index)}
              className={`accordion-question ${openAccordion === index ? 'open' : ''}`}
              aria-expanded={openAccordion === index}
              aria-controls={`faq-content-${index}`}
            >
              {item.question}
              <img
                src={arrowImage}
                alt={openAccordion === index ? 'Collapse' : 'Expand'}
                className={`accordion-icon ${openAccordion === index ? 'rotate' : ''}`}
                style={{ width: '15px', margin: '0' }}
              />
            </button>
            <ul
              id={`faq-content-${index}`}
              className='accordion-answer'
              style={{ maxHeight: openAccordion === index ? '250px' : '0' }}
            >
              {item.answer.map((bullet, i) => (
                <li
                  key={i}
                  className='accordion-answer'
                  dangerouslySetInnerHTML={{ __html: bullet }}
                ></li>
              ))}
            </ul>
          </div>
        ))}
      </section>
    </section>
  );
};

export default FaqComponent;
