import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import '../styles/AuthenticatedRoute.css';
import loginimg from '../assets/login.svg';
import Loading from '../components/Loading';
import { Fade } from 'react-reveal';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500);
  }, []);

  useEffect(() => {
    if (!currentUser) {
      document.title='ragobble - Redirecting'
      const timeoutId = setTimeout(() => {
        navigate('/signin');
      }, 3500);

      return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts
    }
  }, [currentUser, navigate]);

  if (!currentUser) {
    // Return null or another component indicating that the user must be logged in
    return (
        <>
          <div className="auth-container">
            <section className='auth-message-container'>
              <section className='tdp-title-container4'>
              </section>
              <h4 className='auth-message'>You must be logged in to view this page.<br></br><br></br>Redirecting to Sign Up...</h4>
              <img src={loginimg} alt='login-svg'></img>
            </section>
          </div>
        </>
    );
  }

  return <Component {...rest} />;
};

export default AuthenticatedRoute;
