import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode, InvalidTokenError } from 'jwt-decode'; // Correct import
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [currentUser, setCurrentUser] = useState(null);
  const [tokenExpirationTimeout, setTokenExpirationTimeout] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) {
      try {
        const decoded = jwtDecode(authToken);
        setCurrentUser(decoded);

        // Calculate remaining time until token expiration
        const expirationTime = decoded.exp * 1000 - Date.now();
        if (expirationTime > 0) {
          const timeout = setTimeout(() => {
            alert('Your session has expired. Please log in again.');
            logout();
          }, expirationTime);
          setTokenExpirationTimeout(timeout);
        } else {
          // Token has already expired
          alert('Your session has expired. Please log in again.');
          logout();
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        logout();
      }
    } else {
      setCurrentUser(null);
    }

    // Clear the timeout when the component unmounts or authToken changes
    return () => {
      if (tokenExpirationTimeout) {
        clearTimeout(tokenExpirationTimeout);
      }
    };
  }, [authToken]);

  const login = (token) => {
    try {
      const decoded = jwtDecode(token);
      setAuthToken(token);
      setCurrentUser(decoded);
      localStorage.setItem('authToken', token);
      // Calculate remaining time until token expiration
      const expirationTime = decoded.exp * 1000 - Date.now();
      if (expirationTime > 0) {
        const timeout = setTimeout(() => {
          alert('Your session has expired. Please log in again.');
          logout();
        }, expirationTime);
        setTokenExpirationTimeout(timeout);
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
    setCurrentUser(null);
    if (tokenExpirationTimeout) {
      clearTimeout(tokenExpirationTimeout);
    }
    navigate('/signin'); // Redirect to home or login page
  };

  return (
    <AuthContext.Provider value={{ currentUser, authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
