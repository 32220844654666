import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import logo from '../assets/ragobble.svg';
import '../styles/Signinform.css';

function Unsubscribe() {
  const { currentUser, authToken } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if a user is logged in
    if (!currentUser || !authToken) {
      setError(true);
      setMessage('You must be logged in to unsubscribe.');
      return;
    }

    try {
      const response = await axios.post(
        'https://ragobble.com/api/unsubscribe',
        {},
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      setMessage(response.data.message);
      setError(false);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err) {
        setError(true);
        const errorMsg =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Failed to unsubscribe.';
        if (
          errorMsg.includes('ER_DUP_ENTRY') ||
          errorMsg === 'Error processing unsubscribe request'
        ) {
          setMessage('Already Unsubscribed from all non-essential communications');
        } else {
          setMessage(errorMsg);
        }
      }
      
  };

  return (
    <section className="signin-container">
      <section className="signin-left">
        <form className="signin-form" onSubmit={handleSubmit}>
          <Link to="/" style={{ textDecoration: 'none', width: '82%', margin: '0' }}>
            <section className="tdp-title-container3">
              <img className="tdp-second3" src={logo} alt="Ragobble logo" />
              <h6 className="tdp-first3">ragobble</h6>
            </section>
          </Link>
          <h2 style={{ textAlign: 'center' }}>Unsubscribe</h2>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <button className="login-button" type="submit" aria-label="Unsubscribe">
            Unsubscribe
          </button>
          {message && <p style={{ color: error ? 'red' : 'green' }}>{message}</p>}
        </form>
      </section>
      <section className="cright">
        text + images + code © ragobble 2024 · All Rights Reserved
      </section>
    </section>
  );
}

export default Unsubscribe;
