import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';
import uploadLoader from '../assets/uploadLoader.svg';

const UploadedToKb = () => {
  return (
        <div className='file-deletion-container'>
            <Fade>
                <label>Successfully Uploaded to Knowledge Base<img src={uploadLoader} alt ='loader'></img></label>
            </Fade>
        </div>
  );
};

export default UploadedToKb;
