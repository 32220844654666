import React, { useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import '../styles/Signinform.css';
import deleteinput from '../assets/delete.svg';
import ErrorMessageOverlay from './ErrorMessageOverlay';
import logo from '../assets/ragobble.svg';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleClearInput = (fieldName) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: '',
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (formData.newPassword !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const response = await fetch('https://ragobble.com/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword: formData.newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setErrorMessage(data.message || 'Password has been updated successfully.');
        setTimeout(() => {
          navigate('/signin');
        }, 2000);
      } else {
        setErrorMessage(data.error || 'Error resetting password.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while processing your request.');
    }
  };

  return (
    <section className='signin-container'>
      <section className='signin-left'>
        <form className='signin-form' onSubmit={handleResetPassword}>
          <Link to="/" style={{ textDecoration: 'none', width: '82%', margin: '0' }}>
            <section className='tdp-title-container3'>
              <img className='tdp-second3' src={logo} alt='Ragobble logo' />
              <h6 className='tdp-first3'>ragobble</h6>
            </section>
          </Link>
          <h1>Reset Password</h1>
          <section className='label-ipt-container'>
            <label htmlFor='newPassword'>New Password</label>
            <div className='input-with-icon'>
              <input
                type='password'
                id='newPassword'
                name='newPassword'
                value={formData.newPassword}
                onChange={handleInputChange}
                aria-label='New Password'
                required
              />
              {formData.newPassword && (
                <button
                  type='button'
                  className='input-icon2'
                  onClick={() => handleClearInput('newPassword')}
                  aria-label='Clear new password input'
                >
                  <img src={deleteinput} alt='Clear input' />
                </button>
              )}
            </div>
          </section>
          <section className='label-ipt-container'>
            <label htmlFor='confirmPassword'>Confirm New Password</label>
            <div className='input-with-icon'>
              <input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                value={formData.confirmPassword}
                onChange={handleInputChange}
                aria-label='Confirm New Password'
                required
              />
              {formData.confirmPassword && (
                <button
                  type='button'
                  className='input-icon2'
                  onClick={() => handleClearInput('confirmPassword')}
                  aria-label='Clear confirm password input'
                >
                  <img src={deleteinput} alt='Clear input' />
                </button>
              )}
            </div>
          </section>
          <ErrorMessageOverlay message={errorMessage} style={{ marginTop: '10px' }}/>
          <button className='login-button' type='submit' aria-label='Reset Password'>
            Reset Password
          </button>
          <small className='dhac'>
            Back to <Link to='/signin'>Log In</Link>
          </small>
        </form>
      </section>
      <section className='cright'>
        text + images + code © ragobble 2024 · All Rights Reserved
      </section>
    </section>
  );
};

export default ResetPassword;
