import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Signinform.css';
import deleteinput from '../assets/delete.svg';
import ErrorMessageOverlay from './ErrorMessageOverlay';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/ragobble.svg';
import uploadLoader from '../assets/uploadLoader.svg';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleClearInput = (fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: '',
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    try {
      const response = await fetch('https://ragobble.com/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email }),
      });

      const data = await response.json();

      if (response.ok) {
        setErrorMessage(data.message || 'If that email exists, a reset link has been sent.');
      } else {
        setErrorMessage(data.error || 'Error sending reset link.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while processing your request.');
    }
  };

  return (
    <section className='signin-container'>
      <section className='signin-left'>
        <form className='signin-form' onSubmit={handleForgotPassword}>
          <Link to="/" style={{ textDecoration: 'none', width: '82%', margin: '0' }}>
            <section className='tdp-title-container3'>
              <img className='tdp-second3' src={logo} alt='Ragobble logo' />
              <h6 className='tdp-first3'>ragobble</h6>
            </section>
          </Link>
          <h2>Forgot Password</h2>
          <section className='label-ipt-container'>
            <label htmlFor='email'>Email Address</label>
            <div className='input-with-icon'>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                aria-label='Email Address'
              />
              {formData.email && (
                <button
                  type='button'
                  className='input-icon2'
                  onClick={() => handleClearInput('email')}
                  aria-label='Clear email input'
                >
                  <img src={deleteinput} alt='Clear input' />
                </button>
              )}
            </div>
          </section>
          <ErrorMessageOverlay message={errorMessage} />
          <button className='login-button' type='submit' aria-label='Send Reset Link'>
            Submit Reset Link
          </button>
          <small className='dhac'>
            Remembered your password? <Link to='/signin'>Log In</Link>
          </small>
        </form>
      </section>
      <section className='cright'>
        text + images + code © ragobble 2024 · All Rights Reserved
      </section>
    </section>
  );
};

export default ForgotPassword;
