import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import closed from '../assets/closedeye.svg';
import openeye from '../assets/openeye.svg';
import deleteinput from '../assets/delete.svg';
import '../styles/Signup.css';
import agreed2 from '../assets/agreed2.svg';

const Signupform = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [agreed, setAgreed] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordVisibilityToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClearInput = (fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: '',
    }));
  };

  const handleAgreeChange = () => {
    setAgreed((prevAgreed) => !prevAgreed);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!agreed || !formData.email || !formData.password) {
      return; // Prevent submission if not agreed or required fields are empty
    }

    try {
      const response = await fetch('https://ragobble.com/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Signup successful
        setSuccessMessageVisible(true);
        setErrorMessage('');

        // Navigate to /signin route after 2 seconds using window.location
        setTimeout(() => {
          window.location.href = '/signin';
        }, 2000);
      } else {
        // Signup failed
        setSuccessMessageVisible(false);
        setErrorMessage(data.message);

        // Change button text back to "Create Account" after 2 seconds
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error:', error);
      setSuccessMessageVisible(false);
      setErrorMessage('An error occurred while signing up');

      // Change button text back to "Create Account" after 2 seconds
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
  };

  return (
    <div className='signup-container'>
      <section className='form-container-signup'>
        <h1>Get Started</h1>
        <form onSubmit={handleSignUp} className='signup-form'>
          <div className='form-group'>
            <label htmlFor='email' className='email-lbl'>Email Address</label>
            <div className="input-with-icon">
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                required
                aria-label='Email Address'
              />
              {formData.email && (
                <button
                  type='button'
                  className='input-icon1'
                  onClick={() => handleClearInput('email')}
                  aria-label='Clear email input'
                >
                  <img src={deleteinput} alt='Clear input' />
                </button>
              )}
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='password' className='password-lbl'>Password</label>
            <div className="input-with-icon">
              <input
                type={showPassword ? 'text' : 'password'}
                id='password'
                name='password'
                value={formData.password}
                onChange={handleInputChange}
                required
                aria-label='Password'
              />
              {formData.password && (
                <button
                  type='button'
                  className='input-icon'
                  onClick={() => handleClearInput('password')}
                  aria-label='Clear password input'
                >
                  <img src={deleteinput} alt='Clear input' />
                </button>
              )}
              <button
                type='button'
                className='password-toggle'
                onClick={handlePasswordVisibilityToggle}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                <img src={showPassword ? closed : openeye} alt={showPassword ? 'Close eye' : 'Open eye'} />
              </button>
            </div>
          </div>
          <div className="form-group2">
            <input
              type="checkbox"
              id="agree"
              name="agree"
              checked={agreed}
              onChange={handleAgreeChange}
              aria-label='Agree to terms'
            />
            <img alt='Agreed' src={agreed2} style={{ visibility: !agreed ? 'hidden' : 'visible' }} />
            <label htmlFor="agree">
              I have read, understand and agree to the ragobble <Link to="/privacy" target='_blank'>Privacy Policy</Link> and <Link to="/tos" target='_blank'>Terms of Service</Link>.
            </label>
          </div>
          <button
            id='btn-signup'
            type="submit"
            className={`signup-button ${(!agreed || !formData.email || !formData.password) && 'disabled'}`}
            disabled={!agreed || !formData.email || !formData.password}
            aria-label='Create account'
          >
            {errorMessage ? errorMessage : successMessageVisible ? 'Email Verification Sent!' : 'Create account'}
          </button>
          <small className='ahan'>
            Already have an account? <Link to='/signin'>Log In</Link>
          </small>
        </form>
      </section>
      <section className='cright2'>
        <p>text + images + code</p>
        <p className='cright-spacer'>·</p>
        <p>© ragobble 2025</p>
        <p className='cright-spacer'>·</p>
        <p>All Rights Reserved</p>
        <p className='cright-spacer'>·</p>
        <Link to='/tos' target='_blank'>ToS</Link>
        <p className='cright-spacer'>·</p>
        <Link to='/privacy' target='_blank'>Privacy Policy</Link>
      </section>
    </div>
  );
};

export default Signupform;
