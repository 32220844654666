import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';
import uploadLoader from '../assets/spinning-circles.svg';
import halfsquare from '../assets/arightfooter.svg';

const QuestionLimitReached = ({ user }) => {
  return (
        <div className='question-limit-message-container'>
            <Fade>
                <label>{user}</label>
            </Fade>
        </div>
  );
};

export default QuestionLimitReached;
